import React, { Suspense } from 'react';

import { Spinner, Table, Card } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl"; // the locale you want

import FechGetOneReservations from "../APP_RESERVATION_FECH_DATA/FechGetOneReservations";
import FechGetOneReservationLogs from "../APP_RESERVATION_FECH_DATA/FechGetOneReservationLogs";

import BeltReservationApp from '../APP_RESERVATION_COMPONENTS/BeltReservationApp'

import queryString from 'query-string'
import moment from 'moment'
import ParseLinkToArray from "../utils/ParseLinkToArray"

const TabAllReservationChangeLogs = React.lazy(() => import('../APP_RESERVATION_COMPONENTS/TabAllReservationChangeLogs'));

let plLocate = require('moment/locale/pl');
moment.locale('pl', plLocate)

//KLASA WYSWIETLA SZCZEGOLY KONKRETNYCH ZAJEC SZKOLKI
class PageReservationDetails extends React.Component {

    TRAINER_SELECT_DESC = "Wybierz trenera";
    DATE_SELECT_DESC = "Wybierz datę";
    CLASS_SELECT_DESC = "Wybierz szkółke";

    urlDeafulValues = [this.TRAINER_SELECT_DESC, this.CLASS_SELECT_DESC, this.DATE_SELECT_DESC];

    urlValue = "";

    postData = {
        clientId: '',
        sortBy: '',
        ticketStatus: '',
        page: '0'
    };

    constructor(props) {
        super(props);

        this.state = {
            title: "RezerwujKort.pl - Rezerwację",
            restReservationData: {
                successful: false,
                status: '',
                message: '',
                data: []
            },
            restReservationLogData: {
                successful: false,
                status: '',
                message: '',
                data: []
            }
        }
    }

    async componentDidMount() {

        document.title = "Rezerwacje - RezerwujKort.pl";
        registerLocale("pl", pl); // register it with the name you want
        const { location: { search } } = this.props;
        this.state.search = search;
        this.urlValue = queryString.parse(this.state.search);

        this.postData = await ParseLinkToArray(this.urlValue, this.postData, this.urlDeafulValues);

        this.setState({
            restReservationData: await FechGetOneReservations(this.props.match.params.club,
                this.props.match.params.id)
        });
        this.setState({
            restReservationLogData: await FechGetOneReservationLogs(this.props.match.params.club,
                this.props.match.params.id)
        });

        var cc = this.state.restReservationData;
        var ccd = this.state.restReservationLogData;
    }

    render() {

        var loader = "";
        if (!this.state.restReservationData.successful) {
            loader = <div id="loader_div">
                <Spinner animation="border" />
            </div>
            totalRecords = this.state.restReservationData.data
        }

        var totalRecords = "";
        var totalRecordsLogs = "";
        var tabAllTicketReservations = "";
        var tabAllTicketChangeLogs = "";
        var searchPanel = "";
        var baseInfo = "";
        if (this.state.restReservationData.successful) {

            // totalRecords = this.state.restReservationData.data.reservation.length
            // tabAllTicketReservations = <TabAllTicketReservations
            //     club={this.props.match.params.club}
            //     restLessonsData={this.state.restReservationData.data.reservation} /> 

            totalRecordsLogs = this.state.restReservationLogData.data.length
            tabAllTicketChangeLogs = <div>
                <Suspense fallback={<div>
                    <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
                </div>}>
                    <TabAllReservationChangeLogs
                        club={this.props.match.params.club}
                        reservationLogs={this.state.restReservationLogData.data}
                        currentReservation={this.state.restReservationData.data} />
                </Suspense>
            </div>

            baseInfo = <Card style={{ width: '100%' }}>
                <Card.Body>
                    <Card.Title>Informacje o Rezerwacji:</Card.Title>
                    <Table responsive="sm">

                        <tbody>
                            <tr>
                                <td><b>Klient:</b></td>
                                <td>{this.state.restReservationData.data.name}&nbsp;
                                    {this.state.restReservationData.data.surname}&nbsp;
                                    (tel. {this.state.restReservationData.data.phone})</td>
                                <td><b>Trener:</b></td>
                                <td>{this.state.restReservationData.data.trainerName} </td>
                            </tr>
                            <tr>
                                <td><b>Start:</b></td>
                                <td>{this.state.restReservationData.data.dateStart} </td>
                                <td><b>Dzień tygodnia:</b></td>
                                <td>{this.state.restReservationData.data.dayOfWeek} </td>
                            </tr>
                            <tr>
                                <td><b>Stop:</b></td>
                                <td>{this.state.restReservationData.data.dateStop} </td>
                                <td><b>Kort:</b></td>
                                <td>{this.state.restReservationData.data.courtName} </td>
                            </tr>
                            <tr>
                                <td><b>Cena:</b></td>
                                <td>{this.state.restReservationData.data.price} </td>
                                <td><b>Rodzaj płatności:</b></td>
                                <td>{this.state.restReservationData.data.paymentMethodDesc} </td>
                            </tr>
                            <tr>
                                <td><b>Dzielona płatność:</b></td>
                                <td>{this.state.restReservationData.data.splitPayment ? (
                                        <>Tak</>
                                    ) : (
                                        <>Nie</>
                                    )}</td>
                                <td><b>Promocja</b></td>
                                <td>
                                    {this.state.restReservationData.data.promotions && this.state.restReservationData.data.promotions.length > 0 ? (
                                        this.state.restReservationData.data.promotions.join(", ")
                                    ) : (
                                        <>Brak</>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td><b>Czy opłacona:</b></td>
                                <td>
                                    {this.state.restReservationData.data.payed ? (
                                        <>Tak</>
                                    ) : (
                                        <>Nie</>
                                    )}
                                </td>
                                <td><b>Rodzaj rezerwacji:</b></td>
                                <td>{this.state.restReservationData.data.reservationTypeDesc} </td>
                            </tr>
                            <tr>
                                <td><b>Typ rezerwacji:</b></td>
                                <td>{this.state.restReservationData.data.reservationMethodDesc} </td>
                                <td><b>Nieobecność:</b></td>
                                <td>
                                    {this.state.restReservationData.data.absent ? (
                                        <>Tak</>
                                    ) : (
                                        <>Nie</>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td><b>Karnet kort:</b></td>
                                <td>
                                    {this.state.restReservationData.data.ticketCourt ? (
                                        <>Tak</>
                                    ) : (
                                        <>Nie</>
                                    )}
                                </td>
                                <td><b>Karnet trener:</b></td>
                                <td>
                                    {this.state.restReservationData.data.ticketTrainer ? (
                                        <>Tak</>
                                    ) : (
                                        <>Nie</>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td><b>Karnet kort (ile pobrano):</b></td>
                                <td>
                                    {this.state.restReservationData.data.ticketCourt ? (
                                        <>{this.state.restReservationData.data.ticketCourtPrice}</>
                                    ) : (
                                        <>--</>
                                    )}
                                </td>
                                <td><b>Karnet trener (ile pobrano):</b></td>
                                <td>
                                    {this.state.restReservationData.data.ticketCourt ? (
                                        <>{this.state.restReservationData.data.ticketTrainerPrice}</>
                                    ) : (
                                        <>--</>
                                    )}
                                </td>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                                <td><b>Kto utworzył:</b></td>
                                <td>{this.state.restReservationData.data.createdByName} {this.state.restReservationData.data.createdBySurname} <br />
                                    {this.state.restReservationData.data.createdByEmail}</td>
                                <td><b>Kiedy dodany:</b></td>
                                <td>{this.state.restReservationData.data.createdAt}</td>
                            </tr>
                            <tr>
                                <td><b>Czy usunięty:</b></td>
                                <td>
                                    {this.state.restReservationData.data.deleted ? (
                                        <>Tak</>
                                    ) : (
                                        <>Nie</>
                                    )}
                                </td>
                                <td><b>Kiedy usunięty:</b></td>
                                <td>
                                    {this.state.restReservationData.data.deleted ? (
                                        <>{this.state.restReservationData.data.deletedAt}</>
                                    ) : (
                                        <>--</>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td><b>Kto usunął:</b></td>
                                <td>
                                    {this.state.restReservationData.data.deleted ? (
                                        <>{this.state.restReservationData.data.deletedByName} {this.state.restReservationData.data.deletedBySurname}
                                            {this.state.restReservationData.data.deletedByEmail}</>
                                    ) : (
                                        <>--</>
                                    )}
                                </td>
                                <td><b>Opis:</b></td>
                                <td>{this.state.restReservationData.data.description} </td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        }

        return (

            <div>

                <div id="content">

                    <BeltReservationApp club={this.props.match.params.club} />

                    {baseInfo}

                    <br /><br />
                    <b>Lista wszystkich zmian: ({totalRecordsLogs}):</b>
                    <br /><br />

                    {tabAllTicketChangeLogs}

                    {loader}



                </div>
            </div>
        );
    }
}
export default PageReservationDetails;
